<template>
  <div class="contanier">
    <h3>{{ $t("items.new") }}</h3>
    <hr />
    <Item-Form></Item-Form>
  </div>
</template>

<script>
import ItemForm from "@/components/ItemForm.vue";

export default {
  name: "itemnew",
  components: {
    ItemForm
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
